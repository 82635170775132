.c1rhlaq5{background-color:#ffffff;min-width:320px;width:320px;height:100vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.i16ftmsi{margin-top:50px;text-align:center;}
.l517d6e{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex:1;-ms-flex:1;flex:1;gap:20px;}
.a1ctl41z{cursor:pointer;background:rgba(51,51,51,0.05);border-radius:8px;padding:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:20px;}.a1ctl41z:hover{background:rgba(51,51,51,0.15);}
.a95wspf{padding:0 16px;}
.url1y46{color:#333333;font-weight:bold;font-size:20px;}
.e1y3bowf{font-size:12px;color:rgba(51,51,51,0.6);}
.i1lkefdp{background-color:rgba(255,255,255,0.6);border-radius:4px;}
.l1kwdlyt{color:rgba(51,51,51,0.6);}
.c1dez92i{color:rgba(51,51,51,0.2);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:10px;text-align:center;width:100%;margin:20px 0;}
.c1xfkrbu{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
