.iysw8h5{margin:80px 0 0 64px;}
.t1xstx66{color:rgba(51,51,51,0.8);font-size:40px;line-height:140%;font-weight:bold;margin:0 0 64px 0;}
.c1fee24{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;}
.m185ivr7{width:224px;border-right:1px solid rgba(51,51,51,0.2);}
.m33f6he{padding-bottom:8px;}
.mslj2qe{font-size:24px;line-height:140%;font-weight:bold;color:rgba(51,51,51,0.8);margin-bottom:32px;}
.m1okj32i{list-style:none;margin:0;padding:0;}
.me5wgkj{font-size:16px;line-height:140%;color:rgba(51,51,51,0.8);margin-bottom:32px;}
.l1qseck6{color:rgba(51,51,51,0.8);font-size:16px;line-height:140%;}.l1qseck6[aria-current='page']{color:#5db7c9;}
.l1my6ubr{padding-top:8px;margin-bottom:32px;}
.l11wzuhp{color:rgba(51,51,51,0.8);font-size:16px;line-height:140%;font-weight:bold;background-color:transparent;border:none;cursor:pointer;padding:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.c6lp8yp{margin-left:64px;}
